<template>
	<div v-if="showModal">
        <transition name="modal">
            <div class="modal-mask" @click="closeModal">
                <div class="modal-wrapper">
                    <div :class="wide ? 'styled-scrollbar' : 'modal-dialog'" role="document" @click.stop="">
                        <div class="modal-content">
                            <div class="modal-header" v-if="$slots.header">
                                <slot name="header"/>
                            </div>
                            <div class="modal-body" v-if="$slots.body">
                                <slot name="body"/>
                            </div>
                            <div class="modal-footer" v-if="$slots.footer">
                                <slot name="footer"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
	</div>
</template>


<script lang="js">
	export default {
		name: 'GenericModal',
		props: {
			showModal: Boolean,
			wide: {
                type: Boolean,
                default: false
            },
            cancellable: { // if enabled, clicking outside modal will emit 'cancel' 
                type: Boolean,
                default: false
            }
		},
		data() {
			return {
			}
		},
		methods: {
            closeModal(event) {
                if (!this.cancellable) return

                const classList = event.target.classList;
                const classes = Array.from(classList);

                if (classes[0] === 'modal-wrapper') {
                    this.$emit('close');
                }
            },

            handleKeyDown(event) {
                if (event.keyCode === 27) {
                    this.$emit('close');
                }
            }
		},
		mounted() {
            document.addEventListener('keydown', this.handleKeyDown);
		},
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },
	}

</script>

<style lang="scss" scoped>
@import "./src/assets/_variables.scss";

.modal-mask {
    position: absolute;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
}

</style>